<template>
<Page id="admin_results" :title="PageTitle">
    <Loader v-if="loading" :loadingtext="LoadingText"></Loader>
    <div v-else>
        <form>
            <b-row v-for="game in games" :key="game.id" class="align-items-center">
                <b-col cols="2">
                    {{game.gamenr}}
                </b-col>
                <b-col cols="5">
                    <Team v-if="game.hometeamid > 0" :team="team(game.hometeamid)"></Team>
                    <span v-else>Ej bestämt</span>
                </b-col>
                <b-col cols="5">
                    <b-form-input class="result-input-box" type="text" pattern="\d*" v-on:keyup.enter="focusNext($event.target)" @focus="$event.target.select()" @change="SaveResult(game,$event, false)" :value="game.homescore"></b-form-input>
                </b-col>
                <b-col cols="2">
                    {{game.id}}
                </b-col>
                <b-col cols="5">
                    <Team v-if="game.awayteamid > 0" :team="team(game.awayteamid)"></Team>
                    <span v-else>Ej bestämt</span>
                </b-col>
                <b-col cols="5">
                    <b-form-input class="result-input-box" type="text" pattern="\d*" v-on:keyup.enter="focusNext($event.target)" @focus="$event.target.select()" @change="SaveResult(game,$event, true)" :value="game.awayscore"></b-form-input>
                </b-col>
                <b-col cols="12">
                    <hr />
                </b-col>
            </b-row>
        </form>
    </div>
</Page>
</template>

<script>
const Alert = () => import("@/components/miscellaneous/Alert");
const Page = () => import("@/components/common/Page");
const Loader = () => import("@/components/miscellaneous/Loader");
const Team = () => import("@/components/team/Team");

export default {
    name: "AdminResults",
    components: {
        Alert,
        Page,
        Loader,
        Team
    },
    data() {
        return {};
    },
    metaInfo() {
        return {
            title: this.PageTitle,
            meta: [{
                name: "description",
                content: "Ändra matcher!",
            }, ],
        };
    },
    computed: {
        PageName() {
            return process.env.VUE_APP_NAME;
        },
        LoadingText() {
            return "Hämtar matcher...."
        },
        PageTitle() {
            if (this.loading || !this.games) return "Laddar...";
            return "Fyll i resultat"
        },
        loading() {
            return this.$store.getters["adminget/gamesloading"] ||
                this.$store.getters["teams/teamsloading"];
        },
        groups() {
            var groups = this.$store.getters["adminget/games"];
            if (!groups)
                groups = [];
            return groups;
        },
        games() {
            var g = this.groups;
            var games = [];
            for (var i = 0; i < g.length; i++) {
                for (var j = 0; j < g[i].games.length; j++) {
                    games.push(g[i].games[j]);
                }
            }
            return this.$functions.sort(games, "gamedate");
        },
    },
    methods: {
        FixPageScroll() {
            var topPos = 0;
            // finns nästa omgångs?
            var boxes = document.getElementsByClassName("result-input-box");
            if (boxes.length > 0) {
                for (var i = 0; i < boxes.length; i++) {
                    if (boxes[i].value == "") {
                        topPos = this.$functions.position(boxes[i]).top - 50;
                        break;
                    }
                }

            }
            if (topPos > 0)
                window.scrollTo(0, topPos);
        },
        getGames() {
            this.$store.dispatch("adminget/getGames").then(() => {
                  this.FixPageScroll();
            });
        },
        team(tid) {
            return this.$functions.getTeam(tid);
        },
        day(date) {
            return this.$date.GameDate(date);
        },
        time(date) {
            return this.$date.GameTime(date);
        },
        svg(path) {
            return this.$images.svg(path);
        },
        SaveResult(game, value, away) {
            if (away)
                game.awayscore = value;
            else game.homescore = value;
            var postdata = {
                gameid: game.id,
                homescore: game.homescore,
                awayscore: game.awayscore
            };
            this.$store.dispatch("adminpost/SetResult", postdata).then((response) => {
                if (response.status == "ok")
                    this.ShowToast("Lyckat", response.message, "success");
                else {
                    this.ShowToast("Fel!", "Ett fel inträffade!", "danger");
                }
            });
        },
        ShowToast(title, text, variant) {
            this.$bvToast.toast(text, {
                title: title,
                variant: variant,
                solid: true,
            });
        },
        focusNext(elem) {
            const currentIndex = Array.from(elem.form.elements).indexOf(elem);
            elem.form.elements.item(
                currentIndex < elem.form.elements.length - 1 ?
                currentIndex + 1 :
                0
            ).focus();
        }
    },
    mounted() {
        this.getGames();        
    },
};
</script>
