<template>
<Page id="admin_invite" :title="PageTitle">
    <Loader v-if="loading" :loadingtext="LoadingText"></Loader>
    <div v-else :key="ticks">
        <div class="mt-2 mb-2 font-italic text-center">Bjud in användare till din turnering genom att klicka i checkboxarna nedan.</div>
        <div v-if="inviteloading">
            <Loader loadingtext="Skickar inbjudningar"></Loader>
        </div>
        <div v-else>
            <b-row class="table-header mt-4 d-none d-md-flex align-items-center">
                <b-col cols="1">
                    <b-form-checkbox size="lg" @change="CheckAll()" v-model="all" type="checkbox" />
                </b-col>
                <b-col cols="5" lg="3">Epost</b-col>
                <b-col cols="5" lg="3">Användarnamn</b-col>
                <b-col class="d-none d-lg-block" cols="3">Namn</b-col>
                <b-col class="d-none d-lg-block" cols="2">Mobil</b-col>
            </b-row>
            <b-row class="table-row text-left align-items-center" v-for="user in allusers" :key="user.id" :class="{ 'strike-through': user.status >= 3 }">
                <b-col cols="3" md="1">
                    <b-form-checkbox v-if="!UserHasAccess(user.id) && user.status < 3" size="lg" :checked="checked[user.id]" type="checkbox" @change="boxclick(user)" />
                </b-col>
                <b-col cols="9" md="4" lg="3">{{user.email}}</b-col>
                <b-col cols="6" md="4" lg="3">{{user.username}}</b-col>
                <b-col cols="6" md="3">{{user.name + ' ' + user.lastname}}</b-col>
                <b-col cols="4" lg="2" class="d-none d-lg-block">{{user.phone}}</b-col>
                <b-col cols="12" class="d-block d-md-none">
                    <hr>
                </b-col>
            </b-row>
            <b-row class="mt-4 border-top pt-4">
                <b-col>
                    <b-form-checkbox size="lg" v-model="email">Epost</b-form-checkbox>
                </b-col>
                <b-col>
                    <b-form-checkbox size="lg" v-model="sms">SMS</b-form-checkbox>
                </b-col>
                <b-col class="text-right ml-auto">
                    <b-button @click="InviteUsers()" variant="outline-success">Bjud in</b-button>
                </b-col>
            </b-row>
        </div>
    </div>
</Page>
</template>

<script>
const Alert = () => import("@/components/miscellaneous/Alert");
const Page = () => import("@/components/common/Page");
const Loader = () => import("@/components/miscellaneous/Loader");

export default {
    name: "AdminInvite",
    components: {
        Alert,
        Page,
        Loader
    },
    data() {
        return {
            ticks: 0,
            showAdd: false,
            checked: {},
            email: true,
            sms: true,
            all: false,
            inviteloading: false,
        };
    },
    metaInfo() {
        return {
            title: this.PageTitle,
            meta: [{
                name: "description",
                content: "Bjud in användare!",
            }, ],
        };
    },
    computed: {
        PageName() {
            return process.env.VUE_APP_NAME;
        },
        LoadingText() {
            return "Hämtar användare....";
        },
        PageTitle() {
            if (this.loading) return "Laddar...";
            return "Bjud in användare"
        },
        loading() {
            return this.$store.getters["adminget/usersloading"] || this.$store.getters["administratorget/usersloading"];

        },
        allusers() {
            var users = this.$store.getters["administratorget/users"];
            if (!users)
                users = [];
            return users;
        },
        tournamentusers() {
            var users = this.$store.getters["adminget/users"];
            if (!users)
                users = [];
            return users;
        }
    },
    methods: {
        UserHasAccess(userid) {
            var tusers = this.tournamentusers;
            for (var i = 0; i < tusers.length; i++) {
                if (tusers[i].id == userid)
                    return true;
            }

            return false;
        },
        CheckAll() {
            var users = this.allusers;
            for (var i = 0; i < users.length; i++) {
                var u = users[i];
                if (!this.UserHasAccess(u.id)) {
                    this.checked[u.id] = this.all;
                }
            }
            this.ticks++;
        },
        boxclick(user) {
            if (this.checked[user.id])
                this.checked[user.id] = false;
            else this.checked[user.id] = true;
        },
        getUsers() {
            this.$store.dispatch("adminget/getUsers", true);
        },
        svg(path) {
            return this.$images.svg(path);
        },
        InviteUsers() {

            var users = [];
            for (var userid in this.checked) {
                if (this.checked[userid])
                    users.push(userid);
            }

            var postdata = {
                users: users,
                sms: this.sms,
                email: this.email
            };

            this.inviteloading = true;
            this.$store.dispatch("adminpost/TournamentInviteUsers", postdata).then(() => {
                this.ticks++;
                this.getUsers();
                this.ShowToast("Lyckat", "Användare inbjudna", "success");
                this.inviteloading = false;
            });
        },
        ShowToast(title, text, variant) {
            this.$bvToast.toast(text, {
                title: title,
                variant: variant,
                solid: true,
            });
        }
    },
    mounted() {
        this.getUsers();
        this.$store.dispatch("administratorget/getUsers");
    },
};
</script>
