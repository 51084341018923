<template>
<Page id="admin_groups" :title="PageTitle" :key="ticks">
    <Loader v-if="loading" :loadingtext="LoadingText"></Loader>
    <div v-else>
        <Alert v-if="groups.length <= 0" variant="warning" >Inga grupper skapade. Klicka på lägg till för att komma igång!</Alert>
        <div class="my-2 text-center">
            <b-button variant="primary" @click="NewGroup()">Lägg till</b-button>
        </div>
        <div class="mt-5">
            <div v-for="g in groups" :key="g.id" class="mb-5">
                <h3 class="text-center">{{g.name}} ({{g.shortname}}) ({{g.id}})
                    <inline-svg v-if="g.grouptype > '0'" :src="svg('menu/award.svg')" width="24" height="24" />
                    <b-button @click="EditGroup(g)" variant="outline-secondary">Ändra</b-button>
                </h3>
                <h5>Lag</h5>
                <b-list-group>
                    <b-list-group-item v-for="tid in g.teams" :key="tid">
                        <b-row no-gutters class="align-items-center">
                            <b-col>
                                <b-img :src="flag(tid)" class="ml-1" />
                                {{team(tid).dispname}} - <span class="font-italic">{{team(tid).name}}</span> ({{tid}})
                            </b-col>
                            <b-col cols="auto" class="text-right pl-2">
                                <b-button @click="DeleteTeam(g.id, tid)" variant="outline-danger">Ta bort</b-button>
                            </b-col>
                        </b-row>
                    </b-list-group-item>
                    <b-list-group-item>
                        <b-row no-gutters class="align-items-center">
                            <b-col>
                                <TeamSelectNew v-if="!resetteamselect[g.id]" v-model="groupTeamAdd[g.id]" :idlist="TeamsToSelect(g)"></TeamSelectNew>
                            </b-col>
                            <b-col cols="auto" class="text-right pl-2">
                                <b-button :variant="groupTeamAdd[g.id]? 'success': 'outline-secondary'" :disabled="!groupTeamAdd[g.id]" @click="AddTeam(g.id)">Lägg till</b-button>
                            </b-col>
                        </b-row>
                    </b-list-group-item>
                </b-list-group>
                <hr class="mt-5" />
            </div>
        </div>
    </div>

    <b-modal size="lg" :hide-footer="true" :visible="showAdd" :title="addGroupTitle" no-close-on-backdrop no-close-on-esc>
        <AddGroup :groups="groups" v-on:saved="Saved()" v-on:cancel="AddClose()" :group="currentAddGroup"></AddGroup>
    </b-modal>
</Page>
</template>

<script>
const Alert = () => import("@/components/miscellaneous/Alert");
const Page = () => import("@/components/common/Page");
const Loader = () => import("@/components/miscellaneous/Loader");
const AddGroup = () => import("@/components/admin/AddGroup");
const TeamSelectNew = () => import("@/components/miscellaneous/TeamSelectNew");

export default {
    name: "AdminGroups",
    components: {
        Alert,
        Page,
        Loader,
        AddGroup,
        TeamSelectNew
    },
    data() {
        return {
            showAdd: false,
            currentAddGroup: {},
            groupTeamAdd: {},
            resetteamselect: {},
            ticks:0
        };
    },
    metaInfo() {
        return {
            title: this.PageTitle,
            meta: [{
                name: "description",
                content: "Ändra dina uppgifter!",
            }, ],
        };
    },
    computed: {
        PageName() {
            return process.env.VUE_APP_NAME;
        },
        LoadingText() {
            return "Hämtar grupper...."
        },
        PageTitle() {
            if (this.loading) return "Laddar...";
            return "Grupper";
        },
        loading() {
            return (this.$store.getters["adminget/groupsloading"] ||
                this.$store.getters["teams/teamsloading"]);
        },
        groups() {
            var g = this.$store.getters["adminget/groups"];
            if (g)
                return g;
            return [];
        },
        addGroupTitle() {
            if (this.currentAddGroup.name != "")
                return "Editera grupp " + this.currentAddGroup.name;
            return "Skapa ny grupp";
        }

    },
    methods: {
        NewGroup() {
            this.currentAddGroup = {
                name: "",
                shortname: "",
                id: "0",
                tournamentid: "",
                grouptype: "0",
                teams: []
            };
            this.showAdd = true;
        },
        getGroups() {
            this.$store.dispatch("adminget/getGroups");
        },
        EditGroup(g) {
            this.currentAddGroup = this.$functions.clone(g);
            this.showAdd = true;
        },
        AddClose() {
            this.showAdd = false;
        },
        svg(path) {
            return this.$images.svg(path);
        },
        Saved() {
            this.getGroups();
            this.AddClose();
        },
        team(tid) {
            return this.$functions.getTeam(tid);
        },
        flag(tid) {
            var t = this.team(tid);
            return this.$images.teamFlag(t.flag);
        },
        DeleteTeam(groupid, teamid) {
            var data = {
                groupid: groupid,
                teamid: teamid
            };
            this.$store.dispatch("adminpost/DeleteGroupTeam", data).then(() => {
                //this.getGroups();
                this.deleteGroup(groupid, teamid);
            });

        },
        AddTeam(groupid) {
            var team = this.groupTeamAdd[groupid];
            var group = this._getGroup(groupid);
            if (team) {
                var data = {
                    groupid: groupid,
                    teamid: team.id
                };
                this.$store.dispatch("adminpost/AddGroupTeam", data).then(() => {
                    //this.getGroups();
                    group.teams.push(team.id);
                    delete this.groupTeamAdd[groupid];
                    this.resetteamselect[groupid] = true;
                    var self = this;
                    setTimeout(function(){
                        self.resetteamselect[groupid] = false;
                        self.ticks++;
                    },0);
                });
            }
        },
        _getGroup(id) {
            for (var i = 0; i < this.groups.length; i++) {
                if (this.groups[i].id == id)
                    return this.groups[i];
            }
            return false;
        },
        TeamsToSelect(group) {
            var teams = {};
            if (group.groups) {

                for (var i = 0; i < group.groups.length; i++) {
                    var g = this._getGroup(group.groups[i].childgroupid);
                    if (g) {
                        for (var j = 0; j < g.teams.length; j++)
                            if (!this.teamInGroup(group, g.teams[j]))
                                teams[g.teams[j]] = true;
                    }
                }
            }
            return teams;
        },
        teamInGroup(group, teamid) {
            if (group && group.teams) {
                for (var i = 0; i < group.teams.length; i++) {
                    if (group.teams[i] == teamid)
                        return true;
                }
            }
            return false;
        },
        deleteGroup(groupid, teamid) {
            var group = this._getGroup(groupid);
            if (group && group.teams) {
                var index = -1;
                for (var i = 0; i < group.teams.length; i++) {
                    if (group.teams[i] == teamid) {
                        index = i;
                        break;
                    }
                }
                if (index >= 0) {
                    group.teams.splice(index, 1);
                }
            }
        }
    },
    mounted() {
        this.getGroups();
    },
};
</script>
