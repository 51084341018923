<template>
<Page id="admin_news" :title="PageTitle">
    <Loader v-if="loading" :loadingtext="LoadingText"></Loader>
    <div v-else>
        <Alert v-if="news.length <= 0" variant="warning" >Inga nyheter skapade. Klicka på lägg till för att komma igång!</Alert>
        <div class="my-2 text-center">
            <b-button variant="primary" @click="NewNewsEntry()">Lägg till</b-button>
        </div>
        <div class="mt-5">
            <div v-for="entry in news" :key="entry.id" class="d-flex flex-column">
                <div>
                    <h4>{{entry.title}}</h4>
                </div>
                <div class="my-3">
                    <span v-html="text(entry.message)"></span>
                </div>
                <b-row>
                    <b-col>
                        <span class="font-italic">{{date(entry.date)}}</span>
                    </b-col>
                    <b-col class="text-right">
                        <b-button @click="EditNewsEntry(entry)" variant="outline-secondary">Ändra</b-button>
                    </b-col>
                </b-row>
             
                <div>
                    <hr />
                </div>
            </div>
        </div>
    </div>

    <b-modal size="lg" @hide="AddClose" :hide-footer="true" :visible="showAdd" :title="addNewsEntryTitle" no-close-on-backdrop no-close-on-esc>
            <AddNewsEntry v-on:saved="Saved()" v-on:cancel="AddClose()" :entry="currentAddEntry"></AddNewsEntry>
      </b-modal>
</Page>
</template>

<script>
const Alert = () => import("@/components/miscellaneous/Alert");
const Page = () => import("@/components/common/Page");
const Loader = () => import("@/components/miscellaneous/Loader");
const AddNewsEntry = () => import("@/components/admin/AddNewsEntry");

export default {
    name: "AdminNews",
    components: {
        Alert,
        Page,
        Loader,
        AddNewsEntry
    },
    data() {
        return {
            showAdd: false,
            currentAddEntry: {}
        };
    },
    metaInfo() {
        return {
            title: this.PageTitle,
            meta: [{
                name: "description",
                content: "Ändra nyheter",
            }, ],
        };
    },
    computed: {
        PageName() {
            return process.env.VUE_APP_NAME;
        },
        LoadingText() {
            return "Hämtar nyheter...."
        },
        PageTitle() {
            if (this.loading) return "Laddar...";
            return "Nyheter";
        },
        loading() {
            return this.$store.getters["adminget/newsloading"];
        },
        news() {
            var news = this.$store.getters["adminget/news"];
            if (news)
                return news;
            return [];
        },
        addNewsEntryTitle() {
            if (this.currentAddEntry.id > 0)
                return "Editera nyhet";
            return "Skapa nyhet";
        }

    },
    methods: {
        NewNewsEntry() {
            this.currentAddEntry = {                
                id: "0",
                title: "",
                message: "",
                email: true,
                sms: false,
            };
            this.showAdd = true;
        },
        date(date) {
            return this.$date.stringToReadableFormatWithTime(date);
        },
        text(text) {
            return text.replace("\r\n", "<br>");
        },
        getNews() {
            this.$store.dispatch("adminget/getNews");
        },
        EditNewsEntry(q) {
            this.currentAddEntry = this.$functions.clone(q);
            this.showAdd = true;
        },
        AddClose() {
            this.showAdd = false;
        },
        svg(path) {
            return this.$images.svg(path);
        },
        Saved() {
            this.getNews();
            this.AddClose();
        }
    },
    mounted() {
        this.getNews();
    },
};
</script>
