<template>
<Page id="admin_games" :title="PageTitle">
    <Loader v-if="loading" :loadingtext="LoadingText"></Loader>
    <Alert v-else-if="groups.length <= 0" variant="warning" >Inga grupper skapade, börja med att skapa grupper under admin</Alert>
    <div v-else>
        <div v-for="group in groups" :key="group.id" class="mb-5">
            <div class="text-center mb-4">
                <h2>{{group.name}}</h2>
            </div>
            <Alert v-if="group.games.length == 0" variant="info" >Inga matcher i '{{group.name}}'. Klicka på Ny match för att lägga till</Alert>
            <div v-for="game in group.games" :key="game.id" class="mt-2">
                <b-row class="hoverable align-items-center">
                    <b-col>
                        {{game.gamenr}}
                    </b-col>
                    <b-col>
                        {{day(game.gamedate)}}
                    </b-col>
                    <b-col>
                        {{time(game.gamedate)}}
                    </b-col>
                    <b-col>
                        <Team v-if="game.hometeamid > 0" :team="team(game.hometeamid)"></Team>
                        <span v-else>Ej bestämt</span>
                    </b-col>
                    <b-col>
                        <b-row no-gutters class="text-center">
                            <b-col>{{game.homescore}}</b-col>
                            <b-col>-</b-col>
                            <b-col>{{game.awayscore}}</b-col>
                        </b-row>
                    </b-col>
                    <b-col class="text-right">
                        <Team v-if="game.awayteamid > 0" :team="team(game.awayteamid)" away></Team>
                        <span v-else>Ej bestämt</span>
                    </b-col>
                    <b-col cols="auto" class="text-right">
                        <span class="pointer" @click="EditGame(game, group)">
                            <inline-svg :src="svg('common/edit-2.svg')"></inline-svg>
                        </span>
                    </b-col>
                </b-row>
            </div>
            <div class="my-2 text-center">
                <b-button variant="outline-primary" @click="NewGame(group)">Ny match</b-button>
            </div>
            <hr class="mt-5" />
        </div>
    </div>
    <b-modal size="lg" :hide-footer="true" :visible="showAdd" :title="addGameTitle" no-close-on-backdrop no-close-on-esc>
        <AddEditGame :group="currentAddGroup" :game="currentAddGame" v-on:saved="Saved" v-on:cancel="AddClose()"></AddEditGame>
    </b-modal>
</Page>
</template>

<script>
const Alert = () => import("@/components/miscellaneous/Alert");
const Page = () => import("@/components/common/Page");
const Loader = () => import("@/components/miscellaneous/Loader");
const Team = () => import("@/components/team/Team");
const AddEditGame = () => import("@/components/admin/AddGame");

export default {
    name: "AdminGames",
    components: {
        Alert,
        Page,
        Loader,
        Team,
        AddEditGame
    },
    data() {
        return {
            showAdd: false,
            currentAddGame: {},
            currentAddGroup: {}
        };
    },
    metaInfo() {
        return {
            title: this.PageTitle,
            meta: [{
                name: "description",
                content: "Ändra matcher!",
            }, ],
        };
    },
    computed: {
        PageName() {
            return process.env.VUE_APP_NAME;
        },
        LoadingText() {
            return "Hämtar matcher...."
        },
        PageTitle() {
            if (this.loading || !this.games) return "Laddar...";
            return "Matcher"
        },
        loading() {
            return this.$store.getters["adminget/gamesloading"] ||
                this.$store.getters["teams/teamsloading"];
        },
        groups() {
            var groups = this.$store.getters["adminget/games"];
            if (!groups)
                groups = [];
            return groups;
        },
        games() {
            var g = this.groups;
            var games = [];
            for (var i = 0; i < g.length; i++) {
                for (var j = 0; j < g[i].games.length; j++) {
                    games.push(g[i].games[j]);
                }
            }
            return games;
        },
        addGameTitle() {
            if (this.currentAddGame.gamenr != "")
                return "Editera match " + this.currentAddGame.gamenr;
            return "Skapa ny match";
        },
    },
    methods: {
        getGames() {
            this.$store.dispatch("adminget/getGames");
        },
        team(tid) {
            return this.$functions.getTeam(tid);
        },
        day(date) {
            return this.$date.GameDate(date);
        },
        time(date) {
            return this.$date.GameTime(date);
        },
        AddClose() {
            this.showAdd = false;
        },
        svg(path) {
            return this.$images.svg(path);
        },
        Saved(text) {
            this.ShowToast(text, text, "success");
            this.getGames();
            this.AddClose();
        },
        ShowToast(title, text, variant) {
            this.$bvToast.toast(text, {
                title: title,
                variant: variant,
                solid: true,
            });
        },
        NewGame(group) {
            this.currentAddGame = {
                gamenr: this.nextGameNr(),
                gamedate: this.latestDate(),
                hometeamid: 0,
                awayteamid: 0
            };
            this.currentAddGroup = group;
            this.showAdd = true;
        },
        EditGame(game, group) {
            this.currentAddGroup = group;
            this.currentAddGame = game;
            this.showAdd = true;
        },
        nextGameNr() {
            var all = this.$functions.sortint(this.games, "gamenr", true);
            if (all.length > 0)
                return parseInt(all[0].gamenr) + 1;
            return 1;
        },
        latestDate() {
            var all = this.$functions.sort(this.games, "gamedate", true);
            if (all.length > 0)
                return all[0].gamedate;
            return this.$date.dateNow();
        }
    },
    mounted() {
        this.getGames();
    },
};
</script>
