<template>
<Page id="admin_tournament" :title="PageTitle">
      <Loader v-if="loading" loadingtext="Läser in turnering..."></Loader>
      <div v-else>
            <div class="my-3 text-center">
                  <h3>Turneringsbild</h3>
            </div>
            <Loader v-if="ImageLoading" loadingtext="Vänta..."></Loader>
            <b-row no-gutters v-else class="align-items-center">
                  <b-col cols="12" lg="4" class="text-center">
                        <b-img v-if="ShowTournamentImage && Logo" :src="Logo" style="max-width:200px; max-height:200px;"></b-img>
                        <span v-else>Bild saknas...</span>
                        <br />
                        <b-button class="mt-2" @click="Rotate()" variant="primary">
                              <inline-svg :src="svg('common/rotate-cw.svg')" width="20px" height="20px" class="upload-file-svg mb-1" />
                              <span class="ml-2">Rotera</span>
                        </b-button>
                  </b-col>
                  <b-col cols="12" lg="8" class="mt-3 mt-lg-0">
                        <file-upload ref="uploadZone" :drop="true" v-model="file" @input-filter="inputFilter" @input-file="inputFile">
                              <b-row no-gutters class="border-dashed rounded">
                                    <b-col>
                                          <b-row no-gutters class="justify-content-center align-items-center py-3">
                                                <b-col cols="12" md="4">
                                                      <b-button class="" variant="primary">
                                                            <inline-svg :src="svg('common/upload.svg')" width="20px" height="20px" class="upload-file-svg mb-1" />
                                                            <span class="ml-2">Ladda upp fil</span>
                                                      </b-button>
                                                </b-col>
                                                <b-col cols="4" class="d-none d-lg-block"> - eller - </b-col>
                                                <b-col cols="4" class="d-none d-lg-block font-italic">
                                                      Dra och släpp en fil innanför markeringen
                                                </b-col>
                                          </b-row>
                                    </b-col>
                              </b-row>
                        </file-upload>
                  </b-col>
            </b-row>
            <Alert class="mt-3" v-for="(error, index) in fileerrors" :key="index" :variant="error.variant" >{{error.text}}</Alert>
            <hr />
            <div class="my-3 text-center">
                  <h3>{{PageTitle}}</h3>
            </div>
          <AddTournament hidecancel v-on:saved="Saved()" :tournament="tournament"></AddTournament>
          <hr/>
          <div>
            <h3 class="text-center">Statistik</h3>
            <p>Klicka på "Räkna om" för att räkna om</p>
            <div class="text-center m-2"> 
                  <b-button @click="CalcStats()">Räkna om</b-button>
            </div>
          </div>
      </div>
</Page>
</template>

<script>
import FileUpload from "vue-upload-component";
const Alert = () => import("@/components/miscellaneous/Alert");
const Page = () => import("@/components/common/Page");
const Loader = () => import("@/components/miscellaneous/Loader");
const AddTournament = () => import("@/components/administrator/tournaments/AddTournament");

export default {
      name: "AdminTournament",
      components: {
            FileUpload,
            Alert,
            Page,
            Loader,
            AddTournament
      },
      data() {
            return {
                  file: [],
                  fileerrors: [],
                  ShowTournamentImage: true,                
                  orgdata: false,
            };
      },
      metaInfo() {
            return {
                  title: this.PageTitle,
                  meta: [{
                        name: "description",
                        content: "Ändra dina uppgifter!",
                  }, ],
            };
      },
      computed: {          
            PageName() {
                  return process.env.VUE_APP_NAME;
            },
            ImageLoading() {
                  return (
                        this.$store.getters["adminpost/imageloading"] ||
                        this.$store.getters["adminpost/rotateloading"]
                  );
            },
            PageTitle() {
                  if (this.loading || !this.tournament) return "Laddar...";
                  return this.tournament.name;
            },
            loading() {
                  return this.$store.getters["adminget/tournamentloading"];
            },
            tournament() {
                  return this.$store.getters["adminget/tournament"];
            },
            Logo() {
                  if (this.tournament)
                        return this.$images.tournamentImage(this.tournament.uuid, this.tournament.imgversion);
                  return false;
            },
      },
      methods: {
            Rotate() {
                  this.ShowTournamentImage = false;
                  this.$store.dispatch("adminpost/RotateTournamentImage").then(() => {
                        // roterad eller error
                        this.getTournament();
                        this.ShowTournamentImage = true;
                  });
            },
            CalcStats(){
                  this.$store.dispatch("adminpost/CalcStats");
            },
            async uploadFile(file) {
                  file.loading = true;
                  this.$refs.uploadZone.update(file);
                  this.ShowTournamentImage = false;

                  try {
                        await this.DoUploadFile(file.file);
                        this.getTournament();
                        this.ShowTournamentImage = true;
                  } catch (exception) {
                        let error = {
                              text: "Någonting gick med uppladdning av filen: " +
                                    file.name +
                                    ". Var god försök igen.",
                              variant: "danger",
                        };

                        this.fileerrors.push(error);
                        this.ShowTournamentImage = true;
                        throw exception;
                  }

                  this.$refs.uploadZone.update(file, {
                        loading: false,
                  });
            },
            async inputFilter(newFile, oldFile, prevent) {
                  this.fileerrors = [];
                  if (newFile && !oldFile) {
                        // add

                        if (newFile.name && !/\.(jpg|jpeg|png|bmp|heic)$/i.test(newFile.name)) {
                              let error = {
                                    text: "Endast Bild-filer är tillåtna. (" + newFile.name + ")",
                                    variant: "danger",
                              };
                              this.fileerrors.push(error);
                              return prevent();
                        } else if (newFile.size && newFile.size > 5242880) {
                              let error = {
                                    text: "Filen " +
                                          newFile.name +
                                          " är för stor. Filer får max vara 5 MB.",
                                    variant: "danger",
                              };
                              this.fileerrors.push(error);
                              return prevent();
                        }
                        await this.uploadFile(newFile);
                  }
            },
            async inputFile() {},
            async DoUploadFile(file) {
                  let successfulUpload = false;

                  await this.$store
                        .dispatch("adminpost/uploadTournamentImage", file)
                        .then((response) => {
                              if (response.status == 200) {
                                    successfulUpload = true;
                              } else {
                                    // Felhantering :)
                              }
                        });

                  return successfulUpload;
            },
            svg(path) {
                  return this.$images.svg(path);
            },
            getTournament() {
                  this.$store.dispatch("adminget/getTournament").then((resp) => {
                        this.orgdata = JSON.parse(JSON.stringify(resp));
                  });
            },
            Saved(){
                  this.ShowToast("Sparad!", "Turneringen är uppdaterad!", "success");
            },
            ShowToast(title, text, variant) {
                  this.$bvToast.toast(text, {
                        title: title,
                        variant: variant,
                        solid: true,
                  });
            }
      },
      mounted() {
            this.getTournament();
      },
};
</script>
