<template>
<Page id="admin_users" :title="PageTitle">
    <Loader v-if="loading" :loadingtext="LoadingText"></Loader>
    <div v-else :key="ticks">
        <div class="text-right">
            <b-button variant="outline-primary" @click="ShowAddUser()">Lägg till</b-button>
        </div>
        <b-row class="table-header">
            <b-col cols="6">
                Användare
            </b-col>
            <b-col cols="6">
               Åtkomst
            </b-col>
        </b-row>
        <b-row class="table-row border-bottom py-2" v-for="user in users" :key="user.id">
            <b-col cols="6">
                <b-row no-gutters>
                    <b-col cols="auto">{{user.id}}. </b-col>
                    <b-col>{{user.username}}</b-col>
                    <b-col cols="12" md="auto" class="text-muted">{{user.email}}</b-col>
                    <b-col cols="12" md="auto" class="text-muted font-small">{{user.name + ' ' + user.lastname}}</b-col>
                </b-row>
            </b-col>
            <b-col cols="6" :class="userAccessClass(user.access)">
                <b-row no-gutters>
                    <b-col cols="12" md="7" class="py-1" >
                        <b-select :value="user.access" :options="accessLevels" @change="SaveAccess(user, $event)"></b-select>
                    </b-col> 
                    <b-col cols="12" md="5" class="text-right">
                        <b-button variant="primary" @click="DeleteUser(user)">Ta bort</b-button>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>

        <b-modal size="lg" :hide-footer="true" :visible="showAdd" title="Lägg till användare" no-close-on-backdrop no-close-on-esc>
            <AddUser v-on:select="AddUser" v-on:cancel="AddClose()"></AddUser>
        </b-modal>
    </div>
</Page>
</template>

<script>
const Alert = () => import("@/components/miscellaneous/Alert");
const Page = () => import("@/components/common/Page");
const Loader = () => import("@/components/miscellaneous/Loader");
const AddUser = () => import("@/components/admin/AddUser");

import tournamentsenum from "@/helpers/enums/tournaments";

export default {
    name: "AdminUsers",
    components: {
        Alert,
        Page,
        Loader,
        AddUser
    },
    data() {
        return {
            ticks: 0,
            showAdd: false,
        };
    },
    metaInfo() {
        return {
            title: this.PageTitle,
            meta: [{
                name: "description",
                content: "Ändra användare!",
            }, ],
        };
    },
    computed: {
        PageName() {
            return process.env.VUE_APP_NAME;
        },
        LoadingText() {
            return "Hämtar användare....";
        },
        PageTitle() {
            if (this.loading || !this.users) return "Laddar...";
            return "Hantera användare"
        },
        loading() {
            return this.$store.getters["adminget/usersloading"];
        },
        users() {
            var users = this.$store.getters["adminget/users"];
            if (!users)
                users = [];
            return users;
        },
        accessLevels() {
            var items = tournamentsenum.access;
            var accessitems = [];
            for (var id in items) {
                accessitems.push(items[id]);
            }

            return accessitems;
        }
    },
    methods: {
        ShowAddUser() {
            this.showAdd = true;
        },
        AddClose() {
            this.showAdd = false;
        },
        AddUser(data) {
            this.showAdd = false;
            this.SaveAccess({
                id: data.userid
            }, data.access + "", true);
        },
        getUsers() {
            this.$store.dispatch("adminget/getUsers", true);
        },
        userAccess(level) {
            return tournamentsenum.access[level];
        },
        userAccessText(level) {
            return this.userAccess(level).text;
        },
        userAccessClass(level) {
            var cnames = "";
            var a = this.userAccess(level);
            cnames += "bg-" + a.bg;
            cnames += " text-" + a.txt;

            return cnames;
        },
        svg(path) {
            return this.$images.svg(path);
        },
        DeleteUser(user) {
            this.SaveAccess(user, null, true);
        },
        SaveAccess(user, value, update) {
            var postdata = {
                userid: user.id,
                access: value
            };
            if (!value)
                postdata["delete"] = true;

            this.$store.dispatch("adminpost/SetUserTournamentAccess", postdata).then((response) => {
                this.ticks++;
                if (response.status == "ok") {

                    if (update)
                        this.getUsers();
                    if (!value) {
                        this.ShowToast("Lyckat", "Användaren togs bort", "success");
                    } else {
                        this.ShowToast("Lyckat", "Åtkomsten har sparats", "success");
                        user.access = value;
                    }
                } else {
                    this.ShowToast("Fel!", "Ett fel inträffade!", "danger");
                }
            });
        },
        ShowToast(title, text, variant) {
            this.$bvToast.toast(text, {
                title: title,
                variant: variant,
                solid: true,
            });
        }
    },
    mounted() {
        this.getUsers();
        this.$store.dispatch("administratorget/getUsers");
    },
};
</script>
