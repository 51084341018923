<template>
<Page id="admin_questions" :title="PageTitle">
      <Loader v-if="loading" :loadingtext="LoadingText"></Loader>
      <div v-else>
            <Alert v-if="questions.length <= 0" variant="warning" >Inga frågor skapade. Klicka på lägg till för att komma igång!</Alert>
            <div class="my-2 text-center">
                  <b-button variant="primary" @click="NewQuestion()">Lägg till</b-button>
            </div>
            <div class="mt-5">
                  <div v-for="(q, index) in questions" :key="q.id" class="mb-5">
                        <h3 class="text-left">Fråga {{index + 1}}.
                              <b-button @click="EditQuestion(q)" variant="outline-secondary">Ändra</b-button>
                        </h3>
                        <b-row class="mb-1">
                              <b-col class="font-weight-bold">
                                   <h3>{{q.question}}</h3>
                              </b-col>
                              <b-col class="text-right">
                                    ID: {{q.id}}
                              </b-col>
                        </b-row>
                        <b-row class="mb-1">
                              <b-col class="font-weight-bold">
                                    Alternativ
                              </b-col>
                              <b-col cols="12" class="text-left pt-2">
                                    <span class="border px-2 py-1 mx-1 bg-info text-white" v-for="entry in q.options" :key="entry.entry_index">{{entry.entry}}</span>
                              </b-col>
                        </b-row>
                        <b-row class="mb-1">
                              <b-col class="font-weight-bold">
                                    Svar
                              </b-col>
                              <b-col cols="12" class="text-left pt-2">
                                    <span class="border px-2 py-1 mx-1 bg-success text-white" v-for="entry in q.answers" :key="entry.entry_index">{{entry.entry}}</span>
                              </b-col>
                        </b-row>

                        <hr class="mt-5" />
                  </div>
            </div>
      </div>

      <b-modal size="lg" :hide-footer="true" :visible="showAdd" :title="addQuestionTitle" no-close-on-backdrop no-close-on-esc>
            <AddQuestion :groups="groups" :games="games" v-on:saved="Saved()" v-on:cancel="AddClose()" :question="currentAddQuestion"></AddQuestion>
      </b-modal>
</Page>
</template>

<script>
const Alert = () => import("@/components/miscellaneous/Alert");
const Page = () => import("@/components/common/Page");
const Loader = () => import("@/components/miscellaneous/Loader");
const AddQuestion = () => import("@/components/admin/AddQuestion");
const TeamSelectNew = () => import("@/components/miscellaneous/TeamSelectNew");

export default {
      name: "AdminQuestions",
      components: {
            Alert,
            Page,
            Loader,
            AddQuestion,
            TeamSelectNew
      },
      data() {
            return {
                  showAdd: false,
                  currentAddQuestion: {}
            };
      },
      metaInfo() {
            return {
                  title: this.PageTitle,
                  meta: [{
                        name: "description",
                        content: "Ändra frågor",
                  }, ],
            };
      },
      computed: {
            PageName() {
                  return process.env.VUE_APP_NAME;
            },
            LoadingText() {
                  return "Hämtar frågor...."
            },
            PageTitle() {
                  if (this.loading) return "Laddar...";
                  return "Frågor";
            },
            loading() {
                  return (this.$store.getters["adminget/questionsloading"] ||
                        this.$store.getters["teams/teamsloading"]);
            },
            questions() {
                  var q = this.$store.getters["adminget/questions"];
                  if (q)
                        return q.questions;
                  return [];
            },
            games() {
                  var q = this.$store.getters["adminget/questions"];
                  if (q)
                        return q.games;
                  return [];
            },
            groups() {
                  var q = this.$store.getters["adminget/questions"];
                  if (q)
                        return q.groups;
                  return [];
            },
            addQuestionTitle() {
                  if (this.currentAddQuestion.id > 0)
                        return "Editera fråga";
                  return "Skapa ny fråga";
            }

      },
      methods: {
            NewQuestion() {
                  this.currentAddQuestion = {
                        groupid: "",
                        question: "",
                        id: "0",
                        tournamentid: "",
                        type: "2",
                        points: "1",
                        gamenr: "",
                        answers: [],
                        options: [],
                        entry: ""
                  };
                  this.showAdd = true;
            },
            getQuestions() {
                  this.$store.dispatch("adminget/getQuestions");
            },
            EditQuestion(q) {
                  this.currentAddQuestion = this.$functions.clone(q);
                  this.showAdd = true;
            },
            AddClose() {
                  this.showAdd = false;
            },
            svg(path) {
                  return this.$images.svg(path);
            },
            Saved() {
                  this.getQuestions();
                  this.AddClose();
            },           
            answer(question) {
                  if (question.answers && question.answers.length == 1) {
                        return question.answers[0].entry;
                  }

                  return "";
            }           
      },
      mounted() {
            this.getQuestions();
      },
};
</script>
