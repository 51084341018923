<template>
<Page id="admin_payment" :title="PageTitle">
    <Loader v-if="loading" :loadingtext="LoadingText"></Loader>
    <div v-else-if="payment">
        <div>
            <b-row class="my-1">
                <b-col cols="6" class="font-weight-bold">Sista betalningsdatum (sätt samma som turnering)</b-col>
                <b-col cols="6">
                    <b-form-datepicker v-model="payment.date"></b-form-datepicker>
                </b-col>
            </b-row>
            <b-row class="my-1">
                <b-col cols="6" class="font-weight-bold">Grundkostnad</b-col>
                <b-col cols="6">
                    <b-input v-model="payment.amount" placeholder="Kostnad..." class="text-right"></b-input>
                </b-col>
            </b-row>
            <b-row class="my-1">
                <b-col cols="6" class="font-weight-bold">Extra (för vip)</b-col>
                <b-col cols="6">
                    <b-input v-model="payment.extra" placeholder="Extra kostnad..." class="text-right"></b-input>
                </b-col>
            </b-row>
            <b-row class="my-1">
                <b-col cols="6" class="font-weight-bold">Bank</b-col>
                <b-col cols="6">
                    <b-input v-model="payment.bank" placeholder="Bank..."></b-input>
                </b-col>
            </b-row>
            <b-row class="my-1">
                <b-col cols="6" class="font-weight-bold">Konto</b-col>
                <b-col cols="2">
                    <b-input v-model="payment.clearing" placeholder="Clearing" class="text-right"></b-input>
                </b-col>
                <b-col cols="4">
                    <b-input v-model="payment.account" placeholder="Kontonummer" class="text-right"></b-input>
                </b-col>
            </b-row>
            <b-row class="my-1">
                <b-col cols="6" class="font-weight-bold">Kontotyp</b-col>
                <b-col cols="6">
                    <b-form-select v-model="payment.type" :options="accountypes"></b-form-select>
                </b-col>
            </b-row>
            <b-row class="my-1">
                <b-col cols="6" class="font-weight-bold">Swish-nummer</b-col>
                <b-col cols="6">
                    <b-input v-model="payment.swish" placeholder="Swishnummer..."></b-input>
                </b-col>
            </b-row>
            <b-row class="my-1">
                <b-col cols="6" class="font-weight-bold">Provision (heltal %)</b-col>
                <b-col cols="6">
                    <b-input v-model="payment.commission" class="text-right" placeholder="Provision..."></b-input>
                </b-col>
            </b-row>
            <b-row class="my-1">
                <b-col cols="6" class="font-weight-bold">Behåll alla intäkter för Extra (VIP)</b-col>
                <b-col cols="6" class="text-center">
                    <b-form-checkbox v-model="keepextra" size="lg"></b-form-checkbox>
                </b-col>
            </b-row>
            <b-row v-if="paymenthaserrors" class="my-4">
                <b-col cols="12">
                    <Alert class="mt-3" v-for="(error, index) in paymenterrors" :key="index" variant="danger" >{{error}}</Alert>
                </b-col>
            </b-row>
            <b-row class="mt-3">
                <b-col class="text-right">
                    <b-button variant="success" @click="SavePayment()">Spara</b-button>
                </b-col>
            </b-row>
        </div>
        <hr />
        <div>
            <div class="text-center">
                <h3>Inbetalningar</h3>
            </div>
            <b-row class="table-header">
                <b-col cols="3">Användare</b-col>
                <b-col cols="2">Datum</b-col>
                <b-col cols="2" class="text-right">Belopp</b-col>
                <b-col cols="2">Typ</b-col>
                <b-col cols="2">Källa</b-col>
                <b-col cols="1"></b-col>
            </b-row>
            <b-row v-for="userpayment in userpayments" :key="userpayment.id">
                <b-col cols="3">{{username(userpayment.userid)}}</b-col>
                <b-col cols="2">{{date(userpayment.date)}}</b-col>
                <b-col cols="2" class="text-right">{{amount(userpayment.amount)}}</b-col>
                <b-col cols="2">{{type(userpayment.type)}}</b-col>
                <b-col cols="2">{{source(userpayment.source)}}</b-col>
                <b-col cols="1">
                    <b-button variant="secondary" @click="EditUserPayment(userpayment)">Ändra</b-button>
                </b-col>
            </b-row>
            <b-row class="table-footer">
                <b-col cols="5">Totalt {{userspaid}} betalande</b-col>
                <b-col cols="2" class="text-right">{{amount(totalpaid)}}</b-col>

            </b-row>
            <b-row class="mt-3">
                <b-col class="text-center">
                    <b-button variant="success" @click="NewUserPayment()">Ny inbetalning</b-button>
                </b-col>
            </b-row>
        </div>
        <div>
            <b-modal size="lg" @hide="AddClose" :hide-footer="true" :visible="showAddUserPayment" title="Lägg till inbetalning" no-close-on-backdrop no-close-on-esc>
                <b-row class="my-1">
                    <b-col cols="6" class="font-weight-bold">Användare</b-col>
                    <b-col cols="6">
                        <b-form-select v-model="userpayment.userid" :options="usersoptions"></b-form-select>
                    </b-col>
                </b-row>
                <b-row class="my-1">
                    <b-col cols="6" class="font-weight-bold">Datum</b-col>
                    <b-col cols="6">
                        <b-form-input type="text" v-model="userpayment.date"></b-form-input>
                    </b-col>
                </b-row>
                <b-row class="my-1">
                    <b-col cols="6" class="font-weight-bold">Typ av betalning</b-col>
                    <b-col cols="6">
                        <b-form-select @change="SetDefaultAmount()" v-model="userpayment.type" :options="paymenttypes"></b-form-select>
                    </b-col>
                </b-row>
                <b-row class="my-1">
                    <b-col cols="6" class="font-weight-bold">Belopp i SEK</b-col>
                    <b-col cols="6">
                        <b-form-input type="text" pattern="\d*" class="text-right" v-model="userpayment.amount"></b-form-input>
                    </b-col>
                </b-row>
                <b-row class="my-1">
                    <b-col cols="6" class="font-weight-bold">Källa</b-col>
                    <b-col cols="6">
                        <b-form-select v-model="userpayment.source" :options="paymentsources"></b-form-select>
                    </b-col>
                </b-row>
                <b-row class="my-4">
                    <b-col class="text-center">
                        <b-button variant="secondary" class="mx-1" @click="AddClose()">Stäng</b-button>
                        <b-button variant="success" class="mx-1" @click="SaveUserPayment()">Spara</b-button>
                    </b-col>
                </b-row>
            </b-modal>
        </div>
    </div>
    <div v-else>
        <div class="text-center my-5">
            <b-button variant="primary" @click="InitPayment()">Skapa betalning</b-button>
        </div>
    </div>
</Page>
</template>

<script>

/* eslint-disable */
const Alert = () => import("@/components/miscellaneous/Alert");
const Page = () => import("@/components/common/Page");
const Loader = () => import("@/components/miscellaneous/Loader");

import paymentenum from "@/helpers/enums/payment";

export default {
    name: "AdminPayment",
    components: {
        Alert,
        Page,
        Loader
    },
    data() {
        return {
            accountypes: [{
                    value: "",
                    text: "Standard"
                },
                {
                    value: "Personkonto",
                    text: "Personkonto"
                },
                {
                    value: "Företagskonto",
                    text: "Företagskonto"
                }
            ],
            userpayment: {},
            showAddUserPayment: false,
            userspaid: 0
        };
    },
    metaInfo() {
        return {
            title: this.PageTitle,
            meta: [{
                name: "description",
                content: "Betalning",
            }, ],
        };
    },
    computed: {
        PageName() {
            return process.env.VUE_APP_NAME;
        },
        LoadingText() {
            return "Hämtar data...."
        },
        PageTitle() {
            if (this.loading) return "Laddar...";
            return "Betalning"
        },
        loading() {
            return this.$store.getters["adminget/paymentloading"] ||
                this.$store.getters["adminpost/paymentloading"];
        },
        payment() {
            var p = this.$store.getters["adminget/payment"];
            if (p && p.payment && p.payment.tournamentid > 0)
                return p.payment;
            return false;
        },
        paymenthaserrors() {
            var haserror = this.$store.getters["adminpost/paymenterror"];

            return haserror;
        },
        paymenterrors() {
            var errors = [];
            var items = this.$store.getters["adminpost/paymenterrors"];
            for (var id in items) {
                errors.push(items[id]);
            }

            return errors;
        },
        userpayments() {
            var p = this.$store.getters["adminget/payment"];
            if (p && p.userpayments && p.userpayments)
                return p.userpayments;
            return false;
        },
        totalpaid() {
            var total = 0;
            var all = this.userpayments;
            for (var i = 0; i < all.length; i++) {
                total += parseInt(all[i].amount);
                if (parseInt(all[i].amount) >= parseInt(this.payment.amount))
                    this.userspaid++;
            }
            return total;
        },       
        paymenttypes() {
            var types = [];
            for (var i in paymentenum.paymenttype) {
                types.push({
                    value: i,
                    text: paymentenum.paymenttype[i]
                });
            }
            return types;
        },
        paymentsources() {
            var sources = [];
            for (var i in paymentenum.paymentsource) {
                sources.push({
                    value: i,
                    text: paymentenum.paymentsource[i]
                });
            }
            return sources;
        },
        users() {
            return this.$store.getters["adminget/payment"].users;
        },
        usersoptions() {
            var users = [];
            var all = this.users;
            for (var i = 0; i < all.length; i++) {
                users.push({
                    value: all[i].id,
                    text: all[i].username + " (" + all[i].name + " " + all[i].lastname + ")"
                });
            }
            return users;
        },
        keepextra: {
            get() {
                if (this.payment.keep_extra == "1")
                    return true;
                return false;
            },
            set(newValue) {
                if (newValue)
                    this.payment.keep_extra = "1";
                else
                    this.payment.keep_extra = "0";
            }
        }

    },
    methods: {
        username(userid) {
            var user = this.$functions.findById(this.users, "id", userid);
            return user.username;
        },
        date(date) {
            return this.$date.custom(date, "YYYY-MM-DD");
        },
        amount(amount) {
            return this.$formatter.formatCurrency(amount, 0);
        },
        type(type) {
            return paymentenum.paymenttype[type];
        },
        source(source) {
            return paymentenum.paymentsource[source];
        },
        getPayment() {
            this.$store.dispatch("adminget/getPayment");
        },
        svg(path) {
            return this.$images.svg(path);
        },
        SavePayment() {
            var p = this.payment;
            if (!p)
                return;

            this.$store.dispatch("adminpost/SavePayment", p).then((response) => {
                if (response.status == "ok")
                    this.ShowToast("Lyckat", response.message, "success");
                else {
                    this.ShowToast("Fel!", "Ett fel inträffade!", "danger");
                }
            });
        },
        SaveUserPayment() {
            var postdata = this.userpayment;
            this.$store.dispatch("adminpost/SaveUserPayment", postdata).then((response) => {
                if (response.status == "ok") {
                    this.getPayment();
                    this.AddClose();
                    this.ShowToast("Lyckat", response.message, "success");
                } else {
                    this.ShowToast("Fel!", "Ett fel inträffade!", "danger");
                }
            });
        },
        ShowToast(title, text, variant) {
            this.$bvToast.toast(text, {
                title: title,
                variant: variant,
                solid: true,
            });
        },
        InitPayment() {
            var p = this.$store.getters["adminget/payment"];
            var tournament = this.$store.getters["tournamentsg/tournament"];
            if (!tournament)
                return false;
            p.payment.tournamentid = tournament.id;
            p.payment.date = tournament.startdate;
            this.$store.commit("adminget/setPayment", p);
        },
        NewUserPayment() {
            var defaultamount = this.payment.amount;
            this.userpayment = {
                userid: 0,
                date: this.$date.dateNow(),
                amount: defaultamount,
                type: 1,
                source: 10,
                id: 0
            };
            this.showAddUserPayment = true;
        },
        EditUserPayment(uPayment) {
            this.userpayment = uPayment;
            this.showAddUserPayment = true;
        },
        AddClose() {
            this.showAddUserPayment = false;
        },
        SetDefaultAmount() {
            if (this.userpayment) {

                if (this.userpayment.type == 1) // grund
                    this.userpayment.amount = this.payment.amount;
                else if (this.userpayment.type == 10) // extra
                    this.userpayment.amount = this.payment.extra;
                else if (this.userpayment.type == 20) // full
                    this.userpayment.amount = parseInt(this.payment.amount) + parseInt(this.payment.extra);

            }
        }
    },
    mounted() {
        this.getPayment();
    },
};
</script>
