<template>
<Page id="admin_answers" :title="PageTitle">
      <Loader v-if="loading" :loadingtext="LoadingText"></Loader>
      <div v-else>
            <div class="my-2">
                  <b-tabs content-class="my-3">
                        <b-tab title="Tips" active @click="mode = 1">
                              <h3 class="text-center">Tips</h3>
                        </b-tab>
                        <b-tab title="Frågor" @click="mode = 2">
                              <h3 class="text-center">Frågor</h3>
                        </b-tab>
                  </b-tabs>
                  <div v-if="currentUserId > 0">
                        <b-row class="mb-3">
                              <b-col>
                                    <h4>Fyll i svar för {{ currentUser.username }}</h4>
                              </b-col>
                              <b-col class="text-right">
                                    <b-button @click="currentUserId = ''" variant="outline-secondary">Byt användare</b-button>
                              </b-col>
                        </b-row>
                        <hr class="mb-3" />

                        <div v-if="mode == 1">
                              <b-row class="font-weight-bold border-bottom mb-3 h4">
                                    <b-col cols="2"> Nr/Id </b-col>
                                    <b-col cols="4"> Lag </b-col>
                                    <b-col cols="6">
                                          <b-row class="text-center">
                                                <b-col cols="4"> Hemma </b-col>
                                                <b-col cols="4"> Borta </b-col>
                                                <b-col cols="4"> 1-X-2 </b-col>
                                          </b-row>
                                    </b-col>
                              </b-row>
                              <form>
                                    <b-row v-for="game in games" :key="game.id" class="align-items-center">
                                          <b-col cols="2">
                                                <div class="py-1 border-bottom">
                                                      {{ game.gamenr }}
                                                </div>
                                                <div class="py-1">
                                                      {{ game.id }}
                                                </div>
                                          </b-col>
                                          <b-col cols="4">
                                                <div class="border-bottom py-1">
                                                      <Team v-if="game.hometeamid > 0" :team="team(game.hometeamid)"></Team>
                                                      <span v-else>Ej bestämt</span>
                                                </div>

                                                <div class="py-1">
                                                      <Team v-if="game.awayteamid > 0" :team="team(game.awayteamid)"></Team>
                                                      <span v-else>Ej bestämt</span>
                                                </div>
                                          </b-col>
                                          <b-col cols="6">
                                                <b-row>
                                                      <b-col cols="4">
                                                            <b-form-input type="text" pattern="\d*" autocomplete="off" v-on:keyup.enter="focusNext($event.target)" @focus="$event.target.select()" @change="SaveUserTips(game, $event, 'homescore')" :value="getTipsAnswer(game.id).homescore"></b-form-input>
                                                      </b-col>
                                                      <b-col cols="4">
                                                            <b-form-input type="text" pattern="\d*" autocomplete="off" v-on:keyup.enter="focusNext($event.target)" @focus="$event.target.select()" @change="SaveUserTips(game, $event, 'awayscore')" :value="getTipsAnswer(game.id).awayscore"></b-form-input>
                                                      </b-col>
                                                      <b-col cols="4">
                                                            <b-form-input autocomplete="off" v-on:keyup.enter="focusNext($event.target)" @focus="$event.target.select()" @change="SaveUserTips(game, $event, 'bet')" :value="getTipsAnswer(game.id).bet"></b-form-input>
                                                      </b-col>
                                                </b-row>
                                          </b-col>
                                          <b-col cols="12">
                                                <hr />
                                          </b-col>
                                    </b-row>
                              </form>
                        </div>

                        <div v-else-if="mode == 2">
                              <form>
                                    <b-row v-for="(question, index) in questions" :key="question.id" class="align-items-center">
                                          <b-col cols="6">
                                                <div class="font-weight-bold h4">Fråga {{ index + 1 }}.</div>
                                                <div class="h5">
                                                      {{ question.question }}
                                                </div>
                                          </b-col>
                                          <b-col cols="6">
                                                <b-select v-if="question.type == 2" :value="getQuestionAnswer(question.id).answer" :options="getOptionsArray(question)" @change="SaveUserQuestion(question, $event)">

                                                </b-select>
                                                <b-form-input v-if="question.type==1" autocomplete="off" v-on:keyup.enter="focusNext($event.target)" @focus="$event.target.select()" @change="SaveUserQuestion(question, $event)" :value="getQuestionAnswer(question.id).answer"></b-form-input>
                                          </b-col>
                                          <b-col cols="12">
                                                <hr />
                                          </b-col>
                                    </b-row>
                              </form>
                        </div>
                  </div>
                  <div v-else>
                        <b-select @input="getAnswers()" value-field="id" label-field="username" text-field="label" v-model="currentUserId" :options="users"></b-select>
                  </div>
            </div>
      </div>
</Page>
</template>

<script>
const Alert = () => import("@/components/miscellaneous/Alert");
const Page = () => import("@/components/common/Page");
const Loader = () => import("@/components/miscellaneous/Loader");
const Team = () => import("@/components/team/Team");

export default {
      name: "AdminAnswers",
      components: {
            Alert,
            Page,
            Loader,
            Team,
      },
      data() {
            return {
                  mode: 1,
                  currentUserId: "",
                  currentAnswers: {},
            };
      },
      metaInfo() {
            return {
                  title: this.PageTitle,
                  meta: [{
                        name: "description",
                        content: "Ändra dina uppgifter!",
                  }, ],
            };
      },
      computed: {
            currentUser() {
                  var users = this.users;
                  for (var i = 0; i < users.length; i++) {
                        if (users[i].id == this.currentUserId) return users[i];
                  }
                  return false;
            },
            PageName() {
                  return process.env.VUE_APP_NAME;
            },
            LoadingText() {
                  return "Hämtar användare....";
            },
            PageTitle() {
                  if (this.loading) return "Laddar...";
                  else if (this.currentUser) return this.currentUser.email;
                  return "Välj Användare";
            },
            loading() {
                  return (
                        this.$store.getters["adminget/answersloading"] ||
                        this.$store.getters["adminget/usersloading"] ||
                        this.$store.getters["teams/teamsloading"]
                  );
            },
            users() {
                  var g = this.$store.getters["adminget/users"];
                  if (g) {
                        return g;
                  }
                  return [];
            },
            games() {
                  var ans = this.$store.getters["adminget/answers"];
                  if (ans) {
                        return ans.games;
                  }
                  return [];
            },
            questions() {
                  var ans = this.$store.getters["adminget/answers"];
                  if (ans) {
                        return ans.questions;
                  }
                  return [];
            },
      },
      methods: {
            getTipsAnswer(gameid) {
                  var ans = this.$store.getters["adminget/answers"];
                  if (ans) {
                        if (ans.answers && ans.answers.tips && ans.answers.tips[gameid])
                              return ans.answers.tips[gameid];
                  }
                  return {
                        awayscore: "",
                        bet: null,
                        homescore: "",
                  };
            },
            getQuestionAnswer(questionid) {
                  var ans = this.$store.getters["adminget/answers"];
                  if (ans) {
                        if (
                              ans.answers &&
                              ans.answers.questions &&
                              ans.answers.questions[questionid]
                        )
                              return ans.answers.questions[questionid];
                  }
                  return {
                        answer: "",
                  };
            },
            getOptionsArray(q) {
                  var opt = [];
                  if (q.options && q.options.length > 0) {
                        for (var i = 0; i < q.options.length; i++) {
                              var e = q.options[i];
                              if (e.entry == "ALLTEAM") {
                                    opt = this.getAllteamsOptions();
                                    return opt;
                              } else if (e.entry == "GROUP") {
                                    opt = this.getGroupTeamsOptions(q.groupid);
                                    return opt;
                              }
                              opt.push(e.entry);
                        }
                  }
                  return opt;
            },
            getGroupTeamsOptions(gid) {
                  var data = this.$store.getters["adminget/answers"];
                  if (data) {
                        var allteams = {};
                        if (data.teams[gid]) {
                              var gteams = data.teams[gid];
                              for (var i = 0; i < gteams.length; i++)
                                    allteams[gteams[i].id] = gteams[i];
                        }
                        let tarray = [];
                        for (var id2 in allteams) {
                              tarray.push(allteams[id2].dispname);
                        }
                        return tarray.sort();
                  }
                  return [];
            },
            getAllteamsOptions() {
                  var data = this.$store.getters["adminget/answers"];
                  if (data) {
                        var allteams = {};
                        for (var gid in data.teams) {
                              var gteams = data.teams[gid];
                              for (var i = 0; i < gteams.length; i++)
                                    allteams[gteams[i].id] = gteams[i];
                        }
                        let tarray = [];
                        for (var id2 in allteams) {
                              tarray.push(allteams[id2].dispname);
                        }
                        return tarray.sort();
                  }
                  return [];
            },
            getUsers() {
                  this.$store.dispatch("adminget/getUsers", true);
            },
            getAnswers() {
                  this.$store.dispatch("adminget/getAnswers", this.currentUserId);
            },
            svg(path) {
                  return this.$images.svg(path);
            },
            team(tid) {
                  return this.$functions.getTeam(tid);
            },
            flag(tid) {
                  var t = this.team(tid);
                  return this.$images.teamFlag(t.flag);
            },
            SaveUserQuestion(question, value) {
                  var postdata = {
                        type: "q",
                        userid: this.currentUserId,
                        questionid: question.id,
                        value: value,
                  };
                  this.Save(postdata);
            },
            SaveUserTips(game, value, type) {
                  if (type != "bet")
                        value = parseInt(value);
                  var postdata = {
                        type: "g",
                        tipstype: type,
                        userid: this.currentUserId,
                        gameid: game.id,
                        value: value,
                  };
                  this.Save(postdata);
            },
            Save(data) {
                  this.$store.dispatch("adminpost/SetUserAnswer", data).then((response) => {
                        if (response.status == "ok")
                              this.ShowToast("Lyckat", "Tips sparat!", "success");
                        else {
                              this.ShowToast("Fel!", "Ett fel inträffade!", "danger");
                        }
                  });
            },
            ShowToast(title, text, variant) {
                  this.$bvToast.toast(text, {
                        title: title,
                        variant: variant,
                        solid: true,
                  });
            },
            focusNext(elem) {
                  const currentIndex = Array.from(elem.form.elements).indexOf(elem);
                  elem.form.elements
                        .item(
                              currentIndex < elem.form.elements.length - 1 ? currentIndex + 1 : 0
                        )
                        .focus();
            },
      },
      mounted() {
            this.getUsers();
      },
};
</script>
