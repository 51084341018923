export default {
      paymenttype: {            
            1: "Grund",
            10: "Extra",
            20: "Full"
      },          
      paymentsource: {
            1: "Kontant",
            10: "Swish",
            20: "Bank"
      }
};