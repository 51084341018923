<template>
<Page id="admin_winners" :title="PageTitle">
    <Loader v-if="loading" :loadingtext="LoadingText"></Loader>
    <div v-else>
        <b-container>
            <b-row>
                <b-col cols="12" md="6">
                    <div class="text-center">
                        <h3>Totalt</h3>
                    </div>
                    <b-row>
                        <b-col class="font-weight-bold">Totalt inbetalt</b-col>
                        <b-col class="text-right">
                            {{formatAmount(totalpaid)}}
                        </b-col>
                    </b-row>
                    
                    <b-row>
                        <b-col class="font-weight-bold">Avgår provision</b-col>
                        <b-col class="text-right">
                           - {{formatAmount(commission)}}
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="font-weight-bold">Avgår extra</b-col>
                        <b-col class="text-right">
                           - {{formatAmount(totalExtra)}}
                        </b-col>
                    </b-row>
                     <b-row class="border-top">
                        <b-col class="font-weight-bold">Möjligt att betala ut</b-col>
                        <b-col class="text-right">
                            {{formatAmount(real_winnings_total)}}
                        </b-col>
                    </b-row>
                     <b-row class="">
                        <b-col class="font-weight-bold">Utbetalning enligt mall</b-col>
                        <b-col class="text-right">
                            {{formatAmount(sumofwinnersvalueinsek)}}
                        </b-col>
                    </b-row>
                   <b-row class="border-top">
                        <b-col class="font-weight-bold">Vinst/Förlust</b-col>
                        <b-col class="text-right" :class="real_winnings_total-sumofwinnersvalueinsek > 0? 'text-success' : 'text-danger'">
                            {{formatAmount(real_winnings_total-sumofwinnersvalueinsek)}}
                        </b-col>
                    </b-row>
                    
                </b-col>
                <b-col cols="12" md="6">
                    <div class="text-center"><h3>Värden</h3></div>
                    <b-row>
                        <b-col class="font-weight-bold">Grundpris</b-col>
                        <b-col class="text-right">
                            {{formatAmount(payment.amount || 0)}}
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="font-weight-bold">Extra</b-col>
                        <b-col class="text-right">
                            {{formatAmount(payment.extra || 0)}}
                        </b-col>
                    </b-row>
                     <b-row>
                        <b-col class="font-weight-bold">Provision</b-col>
                        <b-col class="text-right">
                            {{payment.commission}} %
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="font-weight-bold">Antal betalande</b-col>
                        <b-col class="text-right">
                            {{nrusers}}
                        </b-col>
                    </b-row>
                     <b-row>
                        <b-col class="font-weight-bold">Antal Extra (VIP)</b-col>
                        <b-col class="text-right">
                            {{nrextra}}
                        </b-col>
                    </b-row>
                     <b-row>
                        <b-col class="font-weight-bold">Behåller extra?</b-col>
                        <b-col class="text-right">
                            {{payment.keep_extra == "1"? "Ja": "Nej"}}
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <hr/>
            <div class="text-center">
                <h3>Mall</h3>
            </div>
            <div v-if="winners">
                <div>
                    <b-row class="table-header">
                        <b-col cols="2">Placering</b-col>
                        <b-col cols="2" class="text-right">Vinst</b-col>
                        <b-col cols="2" class="text-right">Vinst i kr</b-col>
                        <b-col cols="2">Vinsttyp</b-col>
                        <b-col cols="2" class="text-right">Utbetalt?</b-col>
                        <b-col cols="2"></b-col>
                    </b-row>
                    <b-row v-for="winner in winners" :key="winner.placement">
                        <b-col cols="2">{{winner.placement}}</b-col>
                        <b-col cols="2" class="text-right">{{formatWinnerValue(winner.value, winner.valuetype)}}</b-col>
                        <b-col cols="2" class="text-right">{{formatAmount(winnerValueInSEK(winner))}}</b-col>
                        <b-col cols="2">{{vType(winner)}}</b-col>
                        <b-col cols="2" class="text-right">{{winner.payedout > 0 ? 'Ja': 'Nej' }}</b-col>
                        <b-col cols="2" class="text-right"><b-button @click="EditRow(winner)">Ändra</b-button></b-col>
                    </b-row>
                    <b-row class="table-footer">
                        <b-col cols="2"></b-col>
                        <b-col cols="3" class="text-right">{{formatWinnerValue(sumofwinnersvalue, winners[0].valuetype)}}</b-col>
                        <b-col cols="3" class="text-right">{{formatAmount(sumofwinnersvalueinsek)}}</b-col>
                        <b-col cols="4"></b-col>
                    </b-row>
                </div>
            </div>
            <div v-else>
                <Alert variant="info" >Inga vinnare ännu! Klika på lägg till mall</Alert>
                <div class="text-center mt-3">
                    <b-button variant="info" @click="ShowAddTemplate()">Lägg till mall</b-button>
                </div>
            </div>
        </b-container>
    </div>
     <b-modal size="lg" :hide-footer="true" @hide="AddClose()" :visible="showAdd" title="Lägg till vinstmall" no-close-on-backdrop no-close-on-esc>
        <AddTemplate  v-on:add="AddTemplate" v-on:cancel="AddClose()"></AddTemplate>
    </b-modal>
</Page>
</template>

<script>
const Alert = () => import("@/components/miscellaneous/Alert");
const Page = () => import("@/components/common/Page");
const Loader = () => import("@/components/miscellaneous/Loader");
const AddTemplate = () => import("@/components/admin/AddTemplate");

export default {
    name: "AdminWinners",
    components: {
        Alert,
        Page,
        Loader,
        AddTemplate
    },
    data() {
        return {
            nrusers: 0,
            nrextra: 0,
            totalExtra: 0,
            totalwinnings: 0,
            showAdd: false,
            total_winnings_from_template: 0
        };
    },
    metaInfo() {
        return {
            title: this.PageTitle,
            meta: [{
                name: "description",
                content: "Ändra vinnare!",
            }, ],
        };
    },
    computed: {
        PageName() {
            return process.env.VUE_APP_NAME;
        },
        LoadingText() {
            return "Hämtar data...."
        },
        PageTitle() {
            if (this.loading) return "Laddar...";
            return "Vinnare"
        },
        loading() {
            return this.$store.getters["adminget/winnersloading"]
            || this.$store.getters["adminpost/commonloading"];
        },
        payment() {
            var p = this.$store.getters["adminget/winners"];
            if (p && p.payment && p.payment.tournamentid > 0)
                return p.payment;
            return false;
        },
        real_winnings_total(){
            return this.totalpaid- this.commission - this.totalExtra;
        },
        userpayments() {
            var p = this.$store.getters["adminget/winners"];
            if (p && p.userpayments && p.userpayments)
                return p.userpayments;
            return false;
        },
        winners() {
            var p = this.$store.getters["adminget/winners"];
            if (p && p.winners && p.winners.length > 0)
                return p.winners;
            return false;
        },
        totalpaid() {
            var total = 0;
            this.nrusers= 0;
            this.nrextra= 0;
            this.totalExtra= 0;
            var all = this.userpayments;
            for (var i = 0; i < all.length; i++) {
                var p = all[i];
                total += parseInt(p.amount);
                if (parseInt(p.amount) >= parseInt(this.payment.amount))
                    this.nrusers++;
                if (p.type == "10") {
                    this.totalExtra += parseInt(p.amount);
                    this.nrextra++;
                } else if (p.type == "20") {
                    this.totalExtra += (parseInt(p.amount) - parseInt(this.payment.amount));
                    this.nrextra++;
                }
            }
            return total;
        },
        commission() {
            var c = 0;
            if(this.payment)
                c = parseInt(this.payment.commission)
            var com = this.totalpaid * (c / 100);
            return com;
        },
        sumofwinnersvalue(){
            if(this.winners){
                var total =0;
                for(var i = 0; i < this.winners.length; i++){
                    total += parseInt(this.winners[i].value);
                }
                return total;
            }
            else return 0;
        },
        sumofwinnersvalueinsek(){
              if(this.winners){
                var total =0;
                for(var i = 0; i < this.winners.length; i++){
                    total += this.winnerValueInSEK(this.winners[i]);
                }
                return total;
            }
            else return 0;
        }
    },
    methods: {
        getWinners() {
            this.$store.dispatch("adminget/getWinners");
        },
        svg(path) {
            return this.$images.svg(path);
        },
        SaveWinner(game, value, away) {
            if (away)
                game.awayscore = value;
            else game.homescore = value;
            var postdata = {
                gameid: game.id,
                homescore: game.homescore,
                awayscore: game.awayscore
            };
            this.$store.dispatch("adminpost/SetResult", postdata).then((response) => {
                if (response.status == "ok")
                    this.ShowToast("Lyckat", response.message, "success");
                else {
                    this.ShowToast("Fel!", "Ett fel inträffade!", "danger");
                }
            });
        },
        ShowToast(title, text, variant) {
            this.$bvToast.toast(text, {
                title: title,
                variant: variant,
                solid: true,
            });
        },
        ShowAddTemplate(){
            this.showAdd = true;
        },
        AddTemplate(template){
            this.AddClose();
            this.$store.dispatch("adminpost/SaveWinners", template);
        },
        formatAmount(value) {
            return this.$formatter.formatCurrency(value, 0);
        },
        formatWinnerValue(value, type){
            if(type > 0)
            {
                return value+" %";
            }
            return this.formatAmount(value);
        },
        winnerValueInSEK(winner){
            if(winner.valuetype > 0){
                // procent av inbetalt - avgår => real_winnings_total
                var val = this.real_winnings_total * (100 - parseFloat(winner.value));
                return val;

            }
            return parseFloat(winner.value);
        },
        vType(winner){
            return winner.valuetype > 0? "Procent": "Kronor";
        },
        AddClose(){
            this.showAdd = false;
        }
    },
    mounted() {
        this.getWinners();
    },
};
</script>
